@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@layer base {
  html {
    @apply text-neutral-80;
  }
  h1 {
    @apply hd-xl;
  }
  h2 {
    @apply hd-lg;
  }

  h3 {
    @apply hd-lg;
  }

  h4 {
    @apply hd-lg;
  }

  h5 {
    @apply hd-md;
  }

  h6 {
    @apply hd-base;
  }

  .icon {
    height: 1em;
  }
}

.spinner {
  animation: spinner 1000ms linear infinite;
}

@layer utilities {
  .richtext ul {
    @apply list-disc ml-5 my-5;
  }
  .richtext li {
    @apply my-5;
  }
}

.grid-sm {
  @apply grid gap-5;
  grid-template-columns: repeat(auto-fill, minmax(10rem, 1fr));
}
.grid-md {
  @apply grid gap-5;
  grid-template-columns: repeat(auto-fill, minmax(12rem, 1fr));
}
.grid-lg {
  @apply grid gap-5;
  grid-template-columns: repeat(auto-fill, minmax(16rem, 1fr));
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  appearance: none;
  margin: 0;
}

.overlay-after:after {
  --tw-gradient-stops: rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0) 60%;
  @apply absolute top-0 left-0 w-full h-full bg-gradient-to-t;
  content: '';
}

.object-cover-child-img,
.object-contain-child-img {
  position: relative;
}

.object-cover-child-img img {
  @apply object-cover object-center absolute top-0 left-0 w-full h-full;
}

.object-contain-child-img img {
  @apply object-contain object-center absolute top-0 left-0 w-full h-full;
}

.frontpage-grid {
  @apply grid gap-4 md:grid-cols-2 lg:grid-cols-3 lg:grid-rows-2 mb-5;
}

.frontpage-grid > * {
  height: calc(100vw - 2rem);
  @apply block sm:min-h-[200px] sm:max-h-[290px] lg:max-h-max;
}
.frontpage-grid > *:not(:first-child) {
  @apply md:h-56 lg:h-60 xl:h-96;
  min-height: unset !important;
}

.frontpage-grid > *:first-child {
  @apply max-w-full md:col-span-full md:w-full lg:col-span-2 lg:row-span-full lg:w-full lg:h-full;
}

.promotion-text-shadow {
  text-shadow: -0.05em 0.05em 0px #94191c;
}

.globalPadding {
  @apply px-4 lg:px-8;
}

@media print {
  @page {
    margin: 0;
  }
  body {
    margin: 1cm;
  }
}

a[href] {
  @apply underline-offset-4;
}

input[type='search']::-webkit-search-cancel-button {
  display: none;
}

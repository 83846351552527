.pac-container {
  @apply -mt-1 body-base shadow-lg;
}

.pac-logo::after {
  @apply hidden;
}

.pac-item {
  @apply py-4;
}

.pac-icon {
  @apply w-5 h-5 mx-3;
}

#CybotCookiebotDialog h2 {
  @apply hd-lg !important;
}

#CybotCookiebotDialog #CybotCookiebotDialogBodyLevelButtons {
  @apply hidden;
}

#CybotCookiebotDialogTabContent {
  & input {
    & + .CybotCookiebotDialogBodyLevelButtonSlider {
      @apply bg-neutral-50 !important;
    }

    &:checked + .CybotCookiebotDialogBodyLevelButtonSlider {
      @apply bg-neutral-80 !important;
    }
  }

  & .CybotCookiebotDialogBodyLevelButtonSliderWrapper {
    @media screen and (max-width: 640px) {
      @apply w-12 h-7 !important;
    }
  }

  & input:checked + .CybotCookiebotDialogBodyLevelButtonSlider:before {
    @media screen and (max-width: 640px) {
      @apply translate-x-5 !important;
    }
  }

  & .CybotCookiebotDialogBodyLevelButtonSlider {
    @media screen and (max-width: 640px) {
      @apply rounded-[24px] !important;
    }

    &:before {
      @apply w-5 h-5 !important;
    }
  }
}

#CybotCookiebotDialog.CybotEdge #CybotCookiebotDialogBodyEdgeMoreDetails {
  @media screen and (max-width: 640px) {
    @apply mt-4 mb-1 !important;
  }

  & a {
    @apply text-neutral-80 !important;
  }
}

#CybotCookiebotDialog {
  & * {
    @apply body-base !important;
  }

  & label {
    @apply font-bold !important;
  }
}

#CybotCookiebotDialogBodyLevelButtonsSelectPane {
  @media screen and (max-width: 640px) {
    @apply py-2 !important;
  }

  & .CybotCookiebotDialogBodyLevelButtonWrapper {
    @media screen and (max-width: 640px) {
      @apply py-2 !important;
    }
  }
}

#CybotCookiebotDialogNav {
  & .CybotCookiebotDialogNavItemLink {
    @apply hd-lg !important;

    &:hover {
      @apply text-neutral-80 !important;
    }

    &.CybotCookiebotDialogActive {
      @apply text-neutral-80 border-neutral-80 !important;
    }
  }
}

#CybotCookiebotDialogFooter {
  & #CybotCookiebotDialogBodyLevelButtonCustomize,
  & #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelection,
  & .CybotCookiebotDialogBodyButton {
    @apply cap-sm md:cap-base xl:cap-md !important;
    /* @apply w-[200px] !important; */
    @apply border border-neutral-80 !important;
  }

  & #CybotCookiebotDialogBodyButtonAccept,
  & #CybotCookiebotDialogBodyLevelButtonAccept,
  & #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll {
    @apply bg-success-50 border-none !important;
  }

  & .CybotCookiebotDialogBodyButton {
    @apply py-3.5 !important;
    @apply px-6 !important;
    @apply rounded-none !important;
  }

  & #CybotCookiebotDialogBodyButtonsWrapper {
    @apply items-stretch !important;
  }
}

.CybotCookiebotDialogBodyBottomWrapper {
  @apply border-none !important;
}

#CybotCookiebotDialogHeader,
#CybotCookiebotDialogPoweredByText,
#CybotCookiebotDialogPoweredbyCybot {
  @apply hidden !important;
}

#CybotCookiebotDialog.CybotEdge {
  & .CybotCookiebotDialogBodyBottomWrapper {
    @media screen and (min-width: 1280px) {
      @apply mt-0 !important;
    }
  }

  &.CybotMultilevel {
    & #CybotCookiebotDialogFooter {
      @media screen and (min-width: 1280px) {
        @apply static !important;
        @apply h-full flex items-center !important;
      }
    }
    & .CybotCookiebotScrollContainer {
      @media screen and (min-width: 1280px) {
        @apply w-full !important;
      }
    }
  }
}

#CybotCookiebotDialog.CybotEdge
  #CybotCookiebotDialogFooter
  .CybotCookiebotDialogBodyButton,
#CybotCookiebotDialogBodyButtons
  .CybotCookiebotDialogBodyContentControlsWrapper:not(.CybotCookiebotDialogHide)
  + #CybotCookiebotDialogBodyButtonsWrapper
  .CybotCookiebotDialogBodyButton {
  @media screen and (min-width: 1280px) {
    @apply cap-md !important;
    /* @apply w-auto !important; */
    @apply w-[200px] !important;
  }
}
